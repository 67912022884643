/**
 * Gives us the URL to inspec the given tx on etherscan
 * Switches to rinkeby in dev
 */
export const getEtherscanURL = (hash: string) : string => {
  if (process && process.env.NODE_ENV === 'development') {
    return `https://rinkeby.etherscan.io/tx/${hash}`;
  } else {
    return `https://etherscan.io/tx/${hash}`;
  }
};

/**
 * Use this on buttons that need to behave like <a>
 */
export const openLink = (link: string, target: string): void => {
  window.open(link, target);
};
