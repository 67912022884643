























































































































































































































































































































































import Papa from 'papaparse';
import { Carousel3d, Slide } from 'vue-carousel-3d';
import { Component, Vue } from 'vue-property-decorator';
import Accordion from '@/components/Accordion.vue';
import Nav from '@/components/Nav.vue';

@Component({
  components: {
    Carousel3d,
    Slide,
    Nav,
    Accordion
  }
})
export default class NFTS extends Vue {
  account: string | undefined = ''
  shortenedAccount = ''
  tab = 0;
  subtab = 0;

  dreamCatcherData = [];
  neonFutureData = [];
  dominionXData = [];
  sothebysData = [];
  replicantXData = [];
  avatarsData = [];
  nyanAokiData = [];
  oddkeyData = [];

  created () : void {
    Papa.parse('/csvs/dreamcatcher.csv', {
      download: true,
      header: false,
      skipEmptyLines: true,
      complete: (results: any) => {
        this.dreamCatcherData = results.data;
      }
    });

    Papa.parse('/csvs/neonfuture.csv', {
      download: true,
      header: false,
      skipEmptyLines: true,
      complete: (results: any) => {
        this.neonFutureData = results.data;
      }
    });

    Papa.parse('/csvs/replicantx.csv', {
      download: true,
      header: false,
      skipEmptyLines: true,
      complete: (results: any) => {
        this.replicantXData = results.data;
      }
    });

    Papa.parse('/csvs/dominion.csv', {
      download: true,
      header: false,
      skipEmptyLines: true,
      complete: (results: any) => {
        this.dominionXData = results.data;
      }
    });

    Papa.parse('/csvs/oddkey.csv', {
      download: true,
      header: false,
      skipEmptyLines: true,
      complete: (results: any) => {
        this.oddkeyData = results.data;
      }
    });

    Papa.parse('/csvs/avatars.csv', {
      download: true,
      header: false,
      skipEmptyLines: true,
      complete: (results: any) => {
        this.avatarsData = results.data;
      }
    });

    Papa.parse('/csvs/nyanaoki.csv', {
      download: true,
      header: false,
      skipEmptyLines: true,
      complete: (results: any) => {
        this.nyanAokiData = results.data;
      }
    });

    Papa.parse('/csvs/sothebys.csv', {
      download: true,
      header: false,
      skipEmptyLines: true,
      complete: (results: any) => {
        this.sothebysData = results.data;
      }
    });
  }
}
