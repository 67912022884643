export interface Module1State {
  name: string
}

export default {
  namespaced: true as const,

  state: {
    name: ''
  } as Module1State,

  getters: {
    message: (state: Module1State) : string => {
      return `Hello, ${state.name}!`;
    }
  },

  mutations: {
    SET_NAME (state: Module1State, newName: string) : void {
      state.name = newName;
    }
  },

  actions: {
    async loadName (context: { commit: (arg1: string, arg2: string) => void }, payload: { id: string }) : Promise<Module1State> {
      const name = `Name-${payload.id}`; // load it from somewhere
      context.commit('SET_NAME', name);
      return {
        name
      };
    }
  }
};
