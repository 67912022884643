import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import NFTS from '@/views/NFTS.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
import Reward1 from '@/views/Reward1.vue';
import Reward2 from '@/views/Reward2.vue';
import Reward3 from '@/views/Reward3.vue';
import TermsAndConditions from '@/views/TermsAndConditions.vue';

if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter);
}

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/reward-1',
    name: 'Reward1',
    component: Reward1
  },
  {
    path: '/reward-2',
    name: 'Reward2',
    component: Reward2
  },
  {
    path: '/reward-3',
    name: 'Reward3',
    component: Reward3
  },
  {
    path: '/nfts',
    name: 'NFTS',
    component: NFTS
  },
  {
    path: '/privacypolicy',
    name: 'Privacy Policy',
    component: PrivacyPolicy
  },
  {
    path: '/termsandconditions',
    name: 'Terms and Conditions',
    component: TermsAndConditions
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

/**
 * Route Navigation Guards
 *
 * Ref: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
 */
// router.beforeEach((to, from, next) => {
//   const loggedIn = store.jwt && store.walletAddress
//   if (loggedIn && to.path === '/') {
//     return next()
//   } else if ((!loggedIn) && to.path !== '/') {
//     return next()
//   } else {
//     return next()
//   }
// })

export default router;
