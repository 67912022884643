























































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { EthereumNetwork, EthereumProvider } from '@manifoldxyz/manifold-sdk';
import { FALLBACK_PROVIDER, NETWORK } from '@/common/constants';
import { openLink } from '@/common/functions';
import Accordion from '@/components/Accordion.vue';
import Footer from '@/components/Footer.vue';
import Nav from '@/components/Nav.vue';
import store from '@/store/store';

@Component({
  components: {
    Nav,
    Accordion,
    Footer
  }
})
export default class Home extends Vue {
  account: string | undefined = ''
  shortenedAccount = ''
  tab = 0;
  subtab = 0;

  openLink = openLink;
  fallbackProvider = FALLBACK_PROVIDER;
  network = NETWORK;

  async mounted () : Promise<void> {
    // m-connect event
    window.addEventListener(EthereumProvider.ADDRESS_CHANGED, async () => {
      this.account = EthereumProvider.selectedAddress();
      if (this.account) {
        this.shortenedAccount = `${this.account.substring(0, 3)}...${this.account.substring(this.account.length - 4, this.account.length)}`;
      } else {
        localStorage.removeItem('connectedAddress');
        this.account = '';
        this.shortenedAccount = '';
      }
    });

    EthereumProvider.initialize(NETWORK as unknown as EthereumNetwork);
    const prevConnectAddress = localStorage.getItem('connectedAddress');

    if (prevConnectAddress) {
      this.account = prevConnectAddress;
      this.shortenedAccount = `${this.account.substring(0, 3)}...${this.account.substring(this.account.length - 4, this.account.length)}`;
    }

    // store example usage:
    store.commit.module1.SET_NAME('abc');
    // should log the name as 'abc'
    console.log('example store is working: module1.name =', store.state.module1.name);
  }

  async created () : Promise<void> {
    if (NETWORK) {
      this.network = NETWORK;
    }
  }

  selectSubTab (subtab: number) : void {
    this.subtab = subtab;
  }
}
