
















































































import { Component, Vue } from 'vue-property-decorator';
import { FALLBACK_PROVIDER, NETWORK } from '@/common/constants';
import { openLink } from '@/common/functions';
import Nav from '@/components/Nav.vue';

@Component({
  components: {
    Nav
  }
})
export default class Reward3 extends Vue {
  account: string | undefined = ''
  shortenedAccount = ''
  tab = 0;
  subtab = 0;

  openLink = openLink;
  fallbackProvider = FALLBACK_PROVIDER;
  network = NETWORK;

  async created () : Promise<void> {
    if (NETWORK) {
      this.network = NETWORK;
    }
  }
}
