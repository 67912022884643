import Vue from 'vue';
import manifoldCSSReset from '@manifoldxyz/css-reset';
import 'tailwindcss/dist/tailwind.css';
import store from '@/store/store';
import App from './App.vue';
import router from './router';

manifoldCSSReset();

Vue.config.productionTip = false;

new Vue({
  router,
  // NOTE: store.direct is the typed version we reference elsewhere
  store: store.original,
  render: h => h(App)
}).$mount('#app');
