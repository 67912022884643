







































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Accordion extends Vue {
  isOpen = false;

  toggleAccordion () : void {
    this.isOpen = !this.isOpen;
  }
}
