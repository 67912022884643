






















































































































import { Component, Vue } from 'vue-property-decorator';
import { NETWORK } from '@/common/constants';

@Component
export default class Nav extends Vue {
  network: string;
  showMobileMenu = false;

  mounted () : void {
    this.network = NETWORK;

    if (window.location.hash) {
      this.scrollNicelyTo(window.location.hash);
    }
  }

  scrollNicelyTo (selector: string) : void {
    this.showMobileMenu = false;
    const el = document.querySelector(selector) as HTMLElement;

    if (!el) {
      this.$router.push('/' + selector);
    } else {
      el.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  toggleMobileMenu () : void {
    this.showMobileMenu = !this.showMobileMenu;
  }
}
